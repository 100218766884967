import React, { useState, useEffect, useRef, useMemo, ReactNode, useCallback } from 'react';
import * as blazeface from '@tensorflow-models/blazeface';
import '@tensorflow/tfjs';
import { type DetectedBarcode, type BarcodeFormat, BarcodeDetector } from 'barcode-detector';
import Finder from './Finder';
import useCamera from '../hooks/useCamera';
import useScanner from '../hooks/useScanner';
import deepEqual from '../utilities/deepEqual';
import { defaultComponents, defaultConstraints, defaultStyles } from '../misc';
import { IDetectedBarcode, IPoint, IScannerClassNames, IScannerComponents, IScannerStyles, TrackFunction } from '../types';

export interface IScannerProps {
    onScan: (detectedCodes: IDetectedBarcode[]) => void;
    onScanFace?: (result: { faceImage: Blob; detectedBarcodes: DetectedBarcode[] }) => void;
    onError?: (error: unknown) => void;
    constraints?: MediaTrackConstraints;
    formats?: BarcodeFormat[];
    paused?: boolean;
    children?: ReactNode;
    components?: IScannerComponents;
    styles?: IScannerStyles;
    classNames?: IScannerClassNames;
    allowMultiple?: boolean;
    scanDelay?: number;
    faceDelay?: number;
    playSound: (text: string) => void;
}

function clearCanvas(canvas: HTMLCanvasElement | null) {
    if (canvas) {
        const ctx = canvas.getContext('2d');
        if (ctx) {
            ctx.clearRect(0, 0, canvas.width, canvas.height);
        }
    }
}

function onFound(detectedCodes: IDetectedBarcode[], videoEl?: HTMLVideoElement | null, trackingEl?: HTMLCanvasElement | null, tracker?: TrackFunction) {
    try {
        if (!videoEl || !trackingEl) return;
        const canvas = trackingEl;
        const video = videoEl;

        if (!detectedCodes.length || !tracker) {
            clearCanvas(canvas);
            return;
        }

        const displayWidth = video.offsetWidth;
        const displayHeight = video.offsetHeight;

        const resolutionWidth = video.videoWidth;
        const resolutionHeight = video.videoHeight;

        const largerRatio = Math.max(displayWidth / resolutionWidth, displayHeight / resolutionHeight);
        const uncutWidth = resolutionWidth * largerRatio;
        const uncutHeight = resolutionHeight * largerRatio;

        const xScalar = uncutWidth / resolutionWidth;
        const yScalar = uncutHeight / resolutionHeight;
        const xOffset = (displayWidth - uncutWidth) / 2;
        const yOffset = (displayHeight - uncutHeight) / 2;

        const scale = ({ x, y }: IPoint) => ({
            x: Math.floor(x * xScalar),
            y: Math.floor(y * yScalar)
        });

        const translate = ({ x, y }: IPoint) => ({
            x: Math.floor(x + xOffset),
            y: Math.floor(y + yOffset)
        });

        const adjustedCodes = detectedCodes.map((detectedCode) => {
            const { boundingBox, cornerPoints } = detectedCode;

            const { x, y } = translate(scale({ x: boundingBox.x, y: boundingBox.y }));
            const { x: width, y: height } = scale({ x: boundingBox.width, y: boundingBox.height });

            return {
                ...detectedCode,
                cornerPoints: cornerPoints.map((point) => translate(scale(point))),
                boundingBox: DOMRectReadOnly.fromRect({ x, y, width, height })
            };
        });

        canvas.width = video.offsetWidth;
        canvas.height = video.offsetHeight;

        const ctx = canvas.getContext('2d');
        if (ctx) {
            tracker(adjustedCodes, ctx);
        }
    } catch (error) {
        console.error('Error in onFound:', error);
    }
}

export function Scanner(props: IScannerProps) {
    const { onScan, onScanFace, constraints, formats = ['qr_code'], paused = false, components, children, styles, classNames, allowMultiple, scanDelay, faceDelay = 2000, onError, playSound } = props;

    const videoRef = useRef<HTMLVideoElement>(null);
    const pauseFrameRef = useRef<HTMLCanvasElement>(null);
    const trackingLayerRef = useRef<HTMLCanvasElement>(null);
    const [model, setModel] = useState<blazeface.BlazeFaceModel | null>(null);
    const isThrottled = useRef<boolean>(false); // Throttle ref

    const mergedConstraints = useMemo(() => ({ ...defaultConstraints, ...constraints }), [constraints]);
    const mergedComponents = useMemo(() => ({ ...defaultComponents, ...components }), [components]);

    const [isMounted, setIsMounted] = useState(false);
    const [isCameraActive, setIsCameraActive] = useState(true);
    const [constraintsCached, setConstraintsCached] = useState(mergedConstraints);

    const camera = useCamera();

    const { startScanning, stopScanning } = useScanner({
        videoElementRef: videoRef,
        onScan: onScan,
        onFound: (detectedCodes) => onFound(detectedCodes, videoRef.current, trackingLayerRef.current, mergedComponents.tracker),
        formats: formats,
        audio: mergedComponents.audio,
        allowMultiple: allowMultiple,
        retryDelay: mergedComponents.tracker === undefined ? 500 : 10,
        scanDelay: scanDelay
    });

    useEffect(() => {
        setIsMounted(true);
        return () => {
            setIsMounted(false);
            stopScanning();
            camera.stopCamera();
        };
    }, []);

    useEffect(() => {
        if (isMounted) {
            stopScanning();
            startScanning();
        }
    }, [components?.tracker]);

    useEffect(() => {
        if (!deepEqual(mergedConstraints, constraintsCached)) {
            const newConstraints = mergedConstraints;
            if (constraints?.deviceId) delete newConstraints.facingMode;
            setConstraintsCached(newConstraints);
        }
    }, [constraints]);

    const cameraSettings = useMemo(() => ({
        constraints: constraintsCached,
        shouldStream: isMounted && !paused
    }), [constraintsCached, isMounted, paused]);

    const onCameraChange = useCallback(async () => {
        try {
            const videoEl = videoRef.current;
            const canvasEl = pauseFrameRef.current;

            if (cameraSettings.shouldStream) {
                await camera.stopCamera();
                setIsCameraActive(false);

                try {
                    await camera.startCamera(videoEl!, cameraSettings);
                    setIsCameraActive(true);
                } catch (error) {
                    onError?.(error);
                    console.error('Error in onCameraChange:', error);
                }
            } else {
                if (canvasEl && videoEl) {
                    const ctx = canvasEl.getContext('2d');
                    if (ctx) {
                        canvasEl.width = videoEl.videoWidth;
                        canvasEl.height = videoEl.videoHeight;
                        ctx.drawImage(videoEl, 0, 0, canvasEl.width, canvasEl.height);
                    }
                }
                await camera.stopCamera();
                setIsCameraActive(false);
            }
        } catch (error) {
            console.error('Error in onCameraChange:', error);
        }
    }, [cameraSettings]);

    useEffect(() => {
        onCameraChange();
    }, [cameraSettings]);

    const shouldScan = useMemo(() => cameraSettings.shouldStream && isCameraActive, [cameraSettings.shouldStream, isCameraActive]);

    useEffect(() => {
        const loadModel = async () => {
            try {
                const loadedModel = await blazeface.load();
                setModel(loadedModel);
                playSound('Face Recognition Is Initialized');
            } catch (error) {
                console.error('Error loading BlazeFace model:', error);
            }
        };
        loadModel();
    }, []);

    useEffect(() => {
        let animationFrameId: number;

        const detectFace = async () => {
            try {
                if (model && videoRef.current && !isThrottled.current) {
                    const predictions = await model.estimateFaces(videoRef.current, false);
                    if (predictions.length > 0) {
                        clearCanvas(trackingLayerRef.current);
                        drawFaceOutline(predictions[0]);
                        if (!isThrottled.current) {
                            captureAndSendFace(predictions[0]);
                        }
                        throttleFaceDetection(); // Throttle the API call
                    } else {
                        clearCanvas(trackingLayerRef.current);
                    }
                }
                animationFrameId = requestAnimationFrame(detectFace);
            } catch (error) {
                console.error('Error in detectFace:', error);
            }
        };

        if (model && shouldScan) {
            animationFrameId = requestAnimationFrame(detectFace);
        }

        window.addEventListener('resize', resizeCanvasToVideo);

        return () => {
            window.removeEventListener('resize', resizeCanvasToVideo);
            cancelAnimationFrame(animationFrameId);
        };
    }, [model, shouldScan, isThrottled.current]);

    const throttleFaceDetection = () => {
        isThrottled.current = true;
        setTimeout(() => {
            isThrottled.current = false;
        }, 3000); // Throttle for 3 seconds
    };

    const drawFaceOutline = useCallback((face: blazeface.NormalizedFace) => {
        try {
            if (!trackingLayerRef.current || !videoRef.current) return;

            const ctx = trackingLayerRef.current.getContext('2d');
            if (!ctx) return;

            const { topLeft, bottomRight } = face;
            const [x1, y1] = topLeft as [number, number];
            const [x2, y2] = bottomRight as [number, number];

            const videoWidth = videoRef.current.videoWidth;
            const videoHeight = videoRef.current.videoHeight;
            const canvasWidth = trackingLayerRef.current.width;
            const canvasHeight = trackingLayerRef.current.height;

            const scaleX = canvasWidth / videoWidth;
            const scaleY = canvasHeight / videoHeight;

            const scaledX1 = x1 * scaleX;
            const scaledY1 = y1 * scaleY;
            const scaledWidth = (x2 - x1) * scaleX;
            const scaledHeight = (y2 - y1) * scaleY;

            ctx.strokeStyle = 'red';
            ctx.lineWidth = 1;
            ctx.strokeRect(scaledX1, scaledY1, scaledWidth, scaledHeight);
        } catch (error) {
            console.error('Error in drawFaceOutline:', error);
        }
    }, []);

    const resizeCanvasToVideo = useCallback(() => {
        if (videoRef.current && trackingLayerRef.current) {
            trackingLayerRef.current.width = videoRef.current.clientWidth;
            trackingLayerRef.current.height = videoRef.current.clientHeight;
        }
    }, []);

    const captureAndSendFace = useCallback(async (face: blazeface.NormalizedFace) => {
        try {
            const canvas = document.createElement('canvas');
            const video = videoRef.current!;
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;

            const ctx = canvas.getContext('2d')!;
            ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

            // Convert the canvas to a blob
            canvas.toBlob(async (blob) => {
                if (blob && onScanFace) {
                    // Initialize BarcodeDetector with formats from props
                    const barcodeDetector = new BarcodeDetector({ formats });

                    try {
                        // Detect barcodes and QR codes in the canvas
                        const detectedCodes = await barcodeDetector.detect(canvas);

                        // Combine face image with detected barcode/QR code information
                        const result = {
                            faceImage: blob, // Face image as Blob
                            detectedBarcodes: detectedCodes.map(barcode => ({
                                rawValue: barcode.rawValue,
                                format: barcode.format,
                                boundingBox: barcode.boundingBox, // Include boundingBox property
                                cornerPoints: barcode.cornerPoints // Include cornerPoints property
                            })) // Barcode/QR code data
                        };

                        onScanFace(result);
                    } catch (err) {
                        console.error('Barcode detection failed:', err);
                        onScanFace({ faceImage: blob, detectedBarcodes: [] });
                    }
                }
            }, 'image/png');
        } catch (error) {
            console.error('Error in captureAndSendFace:', error);
        }
    }, [onScanFace, formats]);


    useEffect(() => {
        if (shouldScan) {
            clearCanvas(pauseFrameRef.current);
            clearCanvas(trackingLayerRef.current);
            startScanning();
        }
    }, [shouldScan]);

    return (
        <div style={{ ...defaultStyles.container, ...styles?.container }} className={classNames?.container}>
            <video ref={videoRef} style={{ ...defaultStyles.video, ...styles?.video, visibility: paused ? 'hidden' : 'visible' }} className={classNames?.video} autoPlay muted playsInline />
            <canvas
                ref={pauseFrameRef}
                style={{
                    display: paused ? 'block' : 'none',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%'
                }}
            />
            <canvas ref={trackingLayerRef} style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 999 }} />
            <div
                style={{
                    top: 0,
                    left: 0,
                    position: 'absolute',
                    width: '100%',
                    height: '100%'
                }}
            >
                {mergedComponents.finder && (
                    <Finder
                        scanning={isCameraActive}
                        capabilities={camera.capabilities}
                        loading={false}
                        onOff={mergedComponents.onOff}
                        zoom={
                            mergedComponents.zoom && camera.settings.zoom
                                ? {
                                    value: camera.settings.zoom,
                                    onChange: async (value) => {
                                        const newConstraints = {
                                            ...constraintsCached,
                                            advanced: [{ zoom: value }]
                                        };
                                        await camera.updateConstraints(newConstraints);
                                    }
                                }
                                : undefined
                        }
                        torch={
                            mergedComponents.torch
                                ? {
                                    status: camera.settings.torch ?? false,
                                    toggle: async (value) => {
                                        const newConstraints = {
                                            ...constraintsCached,
                                            advanced: [{ torch: value }]
                                        };
                                        await camera.updateConstraints(newConstraints);
                                    }
                                }
                                : undefined
                        }
                        startScanning={async () => await onCameraChange()}
                        stopScanning={async () => {
                            await camera.stopCamera();
                            clearCanvas(trackingLayerRef.current);
                            setIsCameraActive(false);
                        }}
                        border={styles?.finderBorder}
                    />
                )}
                {children}
            </div>
        </div>
    );
}
