import React from 'react'
import { Switch, Route } from 'react-router-dom';

import Dashboard from '../dashboard/components';
import LoginComponent from '../login/components/login';
import LoginMobile from '../login/components/loginMobile';
import DashboardMobile from '../dashboardMobile/components/index'
// import FaceCaptureScanner from '../dashboardMobile/components/FaceCaptureScanner';

import PrivateRoute from './privateRoute'

// import CreatePolicy from '../policyManagement/components/list/createPolicy';
const Routes = () => (
    <Switch>
        <Route exact path='/login' component={LoginComponent} />
        <Route exact path='/login-mobile' component={LoginMobile} />
        <PrivateRoute exact path='/dashboard' component={Dashboard} />        
        <PrivateRoute exact path='/dashboard-mobile' component={DashboardMobile} />        
        {/* <PrivateRoute exact path='/dashboard-mobile' component={FaceCaptureScanner} />         */}
        {/* <PrivateRoute exact path='/policy-management/create-policy' component={CreatePolicy} /> */}
        <PrivateRoute exact path='/' component={Dashboard} />
        
    </Switch>
)

export default Routes
